import axios from 'axios';
import { CONSEQPIEBAR, CONSEQUENCESTAB, FHANOBYCENTER, FHPIE2, FMPIE3, FMPIE4, FMPIE5, FMTAB, REPARTITIONPIEBAR, REPARTITIONTAB, SYNTHESISTAB } from 'utils/constants';

const URL = "http://push-nuke-env-dev.eu-central-1.elasticbeanstalk.com/api";
const PASSWORD = "password=datadevPrvt55468776";
const transformData = (anomaliesByConsequenceForYear2022) => {
  let newAnomaliesByConsequenceForYear2022 = [];
  anomaliesByConsequenceForYear2022.data.forEach(({name, value}, index) => {
    if(name !== null) {
      newAnomaliesByConsequenceForYear2022[0] = {...newAnomaliesByConsequenceForYear2022[0], [name]: value}
    }
  });
  return newAnomaliesByConsequenceForYear2022.map((item) => {
    return {
      fi: item[CONSEQPIEBAR.fi],
      ft: item[CONSEQPIEBAR.ft],
      nf: item[CONSEQPIEBAR.nf],
      no: item[CONSEQPIEBAR.no],
      np: item[CONSEQPIEBAR.np],
      oi: item[CONSEQPIEBAR.oi],
      ot: item[CONSEQPIEBAR.ot],
      sc: item[CONSEQPIEBAR.sc],
    }
  })[0];
}
const transformDataTable = (anomaliesByConsequenceAndCenterForYear2022) => {
  const tmp = anomaliesByConsequenceAndCenterForYear2022.data;
  const newAnomaliesByConsequenceAndCenterForYear2022 = Object.keys(tmp).map((item) => {
    return {
      center: item,
      ...tmp[item]
    }
  });
  return newAnomaliesByConsequenceAndCenterForYear2022;
}
const changeAnomaliesNotation = (anomaliesByTypeForYear2022) => {
  return anomaliesByTypeForYear2022.data.map((item) => {
    return {
      fh: item[REPARTITIONPIEBAR.fh],
      fm: item[REPARTITIONPIEBAR.fm],
      ae: item[REPARTITIONPIEBAR.ae]
    }
  })[0];
}
const transformDataBarFH = (FHAnomaliesByCauseForYear2022) => {
  let newFHAnomaliesByCauseForYear = [];
  FHAnomaliesByCauseForYear2022.data.forEach((element, index) => {
    newFHAnomaliesByCauseForYear[0] = {...newFHAnomaliesByCauseForYear[0], [element.name]: element.value}
  });
  const result = newFHAnomaliesByCauseForYear.map((item) => {
    return {
      ci: item[FHPIE2.ci],
      dp: item[FHPIE2.dp],
      dc: item[FHPIE2.dc],
      mi: item[FHPIE2.mi],
      mic: item[FHPIE2.mic],
      toad: item[FHPIE2.toad],
    }
  });
  return result[0];
}
const transformDataPie5FM = (FMAnomaliesByCauseForYear2022) => {
  
  let newFHAnomaliesByCauseForYear = [];
  FMAnomaliesByCauseForYear2022.data.forEach((element, index) => {
    newFHAnomaliesByCauseForYear[0] = {...newFHAnomaliesByCauseForYear[0], [element.name]: element.value}
  });
  const result = newFHAnomaliesByCauseForYear.map((item) => {
    return {
      cna: item[FMPIE5.cna],
      d: item[FMPIE5.d],
      f: item[FMPIE5.f],
      iai: item[FMPIE5.iai],
      iasc: item[FMPIE5.iasc],
      ina: item[FMPIE5.ina],
      t: item[FMPIE5.t],
    }
  });
  return result[0];
}
const transformDataPie3FM = (FMAnomaliesByCauseForYear2022) => {
  
  let newFHAnomaliesByCauseForYear = [];
  FMAnomaliesByCauseForYear2022.data.forEach((element, index) => {
    newFHAnomaliesByCauseForYear[0] = {...newFHAnomaliesByCauseForYear[0], [element.name]: element.value}
  });
  const result = newFHAnomaliesByCauseForYear.map((item) => {
    return {
      bt: item[FMPIE3.bt],
      ht: item[FMPIE3.ht],
      ns: item[FMPIE3.ns]
    }
  });
  return result[0];
}
const transformDataPie4FM = (FMAnomaliesByOuvTypeForYear2020) => {
  let newFHAnomaliesByCauseForYear = [];
  FMAnomaliesByOuvTypeForYear2020.data.forEach((element, index) => {
    newFHAnomaliesByCauseForYear[0] = {...newFHAnomaliesByCauseForYear[0], [element.name]: element.value}
  });
  const result = newFHAnomaliesByCauseForYear.map((item) => {
    return {
      bc: item[FMPIE4.bc],
      lt: item[FMPIE4.lt],
      lid: item[FMPIE4.lid],
      b: item[FMPIE4.b],
      tb: item[FMPIE4.tb],
    }
  });
  return result[0];
}
export const getData = async () => {
  // synthesis
  const aggregatedAnoEqfPerYear2022 = await axios.get(
    `${URL}/aggregatedAnoEqfPerYear/2022?${PASSWORD}`
  );
  const anoPerYear = await axios.get(
    `${URL}/anoPerYear?${PASSWORD}`
  );
  const eqfPerYear = await axios.get(
    `${URL}/eqfPerYear?${PASSWORD}`
  );
  // repartition
  const anomaliesByTypeForYear2022 = await axios.get(
    `${URL}/anomaliesByTypeForYear/2022?${PASSWORD}`
  );//Pie
  const anomaliesByTypeForYear2023 = await axios.get(
    `${URL}/anomaliesByTypeForYear/2023?${PASSWORD}`
  );
  const anomaliesByTypeForYear2021 = await axios.get(
    `${URL}/anomaliesByTypeForYear/2021?${PASSWORD}`
  );
  const anomaliesByTypeForYear2020 = await axios.get(
    `${URL}/anomaliesByTypeForYear/2020?${PASSWORD}`
  );
  const anomaliesByTypeForYear2019 = await axios.get(
    `${URL}/anomaliesByTypeForYear/2019?${PASSWORD}`
  );
  const anomaliesByTypeForYear2018 = await axios.get(
    `${URL}/anomaliesByTypeForYear/2018?${PASSWORD}`
  );//bar
  const anomaliesByTypeAndLocation2022 = await axios.get(
    `${URL}/anomaliesByTypeAndLocation/2022?${PASSWORD}`
  );//Table
  
  // human
  const FHAnomaliesByCentreAndOuvrageType2022 = await axios.get(
    `${URL}/FHAnomaliesByCentreAndOuvrageType/2022?${PASSWORD}`
  );//Table
  const FHAnomaliesByCauseForYear2022 = await axios.get(
    `${URL}/FHAnomaliesByCauseForYear/2022?${PASSWORD}`
  );
  const FHAnomaliesByCauseForYear2023 = await axios.get(
    `${URL}/FHAnomaliesByCauseForYear/2023?${PASSWORD}`
  );
  const FHAnomaliesByCauseForYear2021 = await axios.get(
    `${URL}/FHAnomaliesByCauseForYear/2021?${PASSWORD}`
  );
  const FHAnomaliesByCauseForYear2020 = await axios.get(
    `${URL}/FHAnomaliesByCauseForYear/2020?${PASSWORD}`
  );
  const FHAnomaliesByCauseForYear2019 = await axios.get(
    `${URL}/FHAnomaliesByCauseForYear/2019?${PASSWORD}`
  );
  const FHAnomaliesByCauseForYear2018 = await axios.get(
    `${URL}/FHAnomaliesByCauseForYear/2018?${PASSWORD}`
  );
  // material
  const FMAnomaliesByDomainForYear2022 = await axios.get(
    `${URL}/FMAnomaliesByDomainForYear/2022?${PASSWORD}`
  );
  const FMAnomaliesByCauseForYear2022 = await axios.get(
    `${URL}/FMAnomaliesByCauseForYear/2022?${PASSWORD}`
  );//3
  const FMAnomaliesByOuvTypeForYear2022 = await axios.get(
    `${URL}/FMAnomaliesByOuvTypeForYear/2022?${PASSWORD}`
  );
  const FMAnomaliesByOuvTypeForYear2020 = await axios.get(
    `${URL}/FMAnomaliesByOuvTypeForYear/2020?${PASSWORD}`
  );
  const FMAnomaliesByOuvTypeForYear2023 = await axios.get(
    `${URL}/FMAnomaliesByOuvTypeForYear/2023?${PASSWORD}`
  );
  const FMAnomaliesByOuvTypeForYear2021 = await axios.get(
    `${URL}/FMAnomaliesByOuvTypeForYear/2021?${PASSWORD}`
  );
  const FMAnomaliesByOuvTypeForYear2019 = await axios.get(
    `${URL}/FMAnomaliesByOuvTypeForYear/2019?${PASSWORD}`
  );
  const FMAnomaliesByOuvTypeForYear2018 = await axios.get(
    `${URL}/FMAnomaliesByOuvTypeForYear/2018?${PASSWORD}`
  );
  //4
  const FMAnomaliesByTensionTypeForYear2022 = await axios.get(
    `${URL}/FMAnomaliesByTensionTypeForYear/2022?${PASSWORD}`
  );//5
  // consequences
  //Pie
  const anomaliesByConsequenceForYear2022 = await axios.get(
    `${URL}/anomaliesByConsequenceForYear/2022?${PASSWORD}`
  );
  //Bar
  const anomaliesByConsequenceForYear2023 = await axios.get(
    `${URL}/anomaliesByConsequenceForYear/2023?${PASSWORD}`
  );
  const anomaliesByConsequenceForYear2021 = await axios.get(
    `${URL}/anomaliesByConsequenceForYear/2021?${PASSWORD}`
  );
  const anomaliesByConsequenceForYear2020 = await axios.get(
    `${URL}/anomaliesByConsequenceForYear/2020?${PASSWORD}`
  );
  const anomaliesByConsequenceForYear2019 = await axios.get(
    `${URL}/anomaliesByConsequenceForYear/2019?${PASSWORD}`
  );
  const anomaliesByConsequenceForYear2018 = await axios.get(
    `${URL}/anomaliesByConsequenceForYear/2018?${PASSWORD}`
  );
  const anomaliesByConsequenceAndCenterForYear2022 = await axios.get(
    `${URL}/anomaliesByConsequenceAndCenterForYear/2022?${PASSWORD}`
  );
  return {
    synthesis: {
      aggregatedAnoEqfPerYear2022: aggregatedAnoEqfPerYear2022.data.map((item) => {
        return {
          center: item[SYNTHESISTAB.center],
          nb_ano: item[SYNTHESISTAB.nb_ano],
          nb_eqf: item[SYNTHESISTAB.nb_eqf],
          ratio: item[SYNTHESISTAB.ratio],
        }
      }),
      anoPerYear: anoPerYear.data,
      eqfPerYear: eqfPerYear.data
    },
    repartition: {
      anomaliesByTypeForYear2022: [changeAnomaliesNotation(anomaliesByTypeForYear2022)], // graphes
      anomaliesByTypeForYear: [
        {
          year: '2023',
          ...changeAnomaliesNotation(anomaliesByTypeForYear2023)
        },
        {
          year: '2022',
          ...changeAnomaliesNotation(anomaliesByTypeForYear2022)
        },
        {
          year: '2021',
          ...changeAnomaliesNotation(anomaliesByTypeForYear2021),
        },
        {
          year: '2020',
          ...changeAnomaliesNotation(anomaliesByTypeForYear2020)
        },
        {
          year: '2019',
          ...changeAnomaliesNotation(anomaliesByTypeForYear2019)
        },
        {
          year: '2018',
          ...changeAnomaliesNotation(anomaliesByTypeForYear2018)
        }
      ],
      anomaliesByTypeAndLocation2022: anomaliesByTypeAndLocation2022.data.map((item) => { // Table
        return {
          center: item[REPARTITIONTAB.center],
          fh: item[REPARTITIONTAB.fh] || 0,
          fm: item[REPARTITIONTAB.fm] || 0,
          ae: item[REPARTITIONTAB.ae] || 0,
        }

      })
    },
    human: {
      FHAnomaliesByCentreAndOuvrageType2022: FHAnomaliesByCentreAndOuvrageType2022.data.map((item) => {
        return {
          center: item[FHANOBYCENTER.center],
          cutOffBlock: item[FHANOBYCENTER.cutOffBlock],
          transitLine: item[FHANOBYCENTER.transitLine],
          InterBreakerLine: item[FHANOBYCENTER.InterBreakerLine],
          station: item[FHANOBYCENTER.station],
          btCell: item[FHANOBYCENTER.btCell],
          fhTotal: item[FHANOBYCENTER.fhTotal]
        }
      }),
      FHAnomaliesByCauseForYear2022: [transformDataBarFH(FHAnomaliesByCauseForYear2022)],
      FHAnomaliesByCauseForYear: [
        {year: "2023", ...transformDataBarFH(FHAnomaliesByCauseForYear2023)},
        {year: "2022", ...transformDataBarFH(FHAnomaliesByCauseForYear2022)},
        {year: "2021", ...transformDataBarFH(FHAnomaliesByCauseForYear2021)},
        {year: "2020", ...transformDataBarFH(FHAnomaliesByCauseForYear2020)},
        {year: "2019", ...transformDataBarFH(FHAnomaliesByCauseForYear2019)},
        {year: "2018", ...transformDataBarFH(FHAnomaliesByCauseForYear2018)}
      ],
    },
    material: {
      FMAnomaliesByDomainForYear2022: FMAnomaliesByDomainForYear2022.data.map((item) => {
        return {
          center: item[FMTAB.center],
          bt: item[FMTAB.bt] || 0,
          ht: item[FMTAB.ht] || 0,
          ns: item[FMTAB.ns] || 0,
        }
      }),
      FMAnomaliesByOuvTypeForYear: [
        {year: "2023", ...transformDataPie4FM(FMAnomaliesByOuvTypeForYear2023)},
        {year: "2022", ...transformDataPie4FM(FMAnomaliesByOuvTypeForYear2022)},
        {year: "2021", ...transformDataPie4FM(FMAnomaliesByOuvTypeForYear2021)},
        {year: "2020", ...transformDataPie4FM(FMAnomaliesByOuvTypeForYear2020)},
        {year: "2019", ...transformDataPie4FM(FMAnomaliesByOuvTypeForYear2019)},
        {year: "2018", ...transformDataPie4FM(FMAnomaliesByOuvTypeForYear2018)},
      ],
      //4.3
      FMAnomaliesByTensionTypeForYear2022: [transformDataPie3FM(FMAnomaliesByTensionTypeForYear2022)],
      //4.5
      FMAnomaliesByOuvTypeForYear2022: [transformDataPie4FM(FMAnomaliesByOuvTypeForYear2022)],
      FMAnomaliesByCauseForYear2022: [transformDataPie5FM(FMAnomaliesByCauseForYear2022)]
    },
    consequences: {
      anomaliesByConsequenceForYear2022: [transformData(anomaliesByConsequenceForYear2022)],
      anomaliesByConsequenceForYear: [
        {year: "2023", ...transformData(anomaliesByConsequenceForYear2023)},
        {year: "2022", ...transformData(anomaliesByConsequenceForYear2022)},
        {year: "2021", ...transformData(anomaliesByConsequenceForYear2021)},
        {year: "2020", ...transformData(anomaliesByConsequenceForYear2020)},
        {year: "2019", ...transformData(anomaliesByConsequenceForYear2019)},
        {year: "2018", ...transformData(anomaliesByConsequenceForYear2018)},
      ],
      anomaliesByConsequenceAndCenterForYear2022: transformDataTable(anomaliesByConsequenceAndCenterForYear2022).map((item) => {
        return {
          center: item[CONSEQUENCESTAB.center] || 0,
          fi: item[CONSEQUENCESTAB.fi] || 0,
          ft: item[CONSEQUENCESTAB.ft] || 0,
          nf: item[CONSEQUENCESTAB.nf] || 0,
          no: item[CONSEQUENCESTAB.no] || 0,
          oi: item[CONSEQUENCESTAB.oi] || 0,
          ot: item[CONSEQUENCESTAB.ot] || 0,
          sq: item[CONSEQUENCESTAB.sq] || 0,
          np: item[CONSEQUENCESTAB.np] || 0,
        }
      })
    }
    
  }
};