import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { styled } from "styled-components";
import {useDeviceWidth} from "utils/useDeviceDetect";
import Indicators from "./Indicators";
import { getXAxisTickBarMargin, getYAxisTickBarMargin, getYAxisBarWidth } from "utils";
import { sizes } from "utils/breakpoints";
const StyledRow = styled(Row)`
    &.bar-chart-row {
      height: 100%;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 64px;
      .bar-chart-item-2 {
        flex: 1;
        align-self: center;
      }
      .custom-tooltip {
          width: 300px;
          background:rgba(255,255,255, 0.9);
          padding: 15px;
          
          .year {
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 10px;
          }
          .value-label {
            font-weight: bold;
            font-size: 14px;
              & > .col: nth-child(1){
                  flex-basis: 175px;
              }
          }
      }
    }
  }
`;
const BacBarChart = ({ data, columnsNumber, colors, barLabels }) => {
  const wdth = useDeviceWidth();
  const getBar = () => {
    const keysArr = Object.keys(data[0]);
    const barArr = [];
    keysArr.filter((item) => item !== 'year').forEach((item, index) => {
      barArr.push(
        <Bar key={index}
          dataKey={item}
          stackId="a"
          fill={colors[index]}
          animationDuration={250}
        />
      );
    });
    return barArr;
  };
  return (
    <StyledRow className="bar-chart-row">
      <Col sm="12" md="7">
        <ResponsiveContainer
          width={wdth <= sizes.sm ? 300 : "100%"}
          height={375}
          className="responsive-container"
        >
          <BarChart
            data={data}
            margin={{ top: 0, right: 0, left: 0, bottom: 24 }}
          >
            <CartesianGrid strokeDasharray="25" vertical={false} />
            <XAxis dataKey="year" tickLine={false} tickMargin={getXAxisTickBarMargin(wdth)} />
            <YAxis
              type="number"
              axisLine={false}
              tickLine={false}
              tickMargin={getYAxisTickBarMargin(wdth)}
              width={getYAxisBarWidth(wdth)}
            />
            <Tooltip offset={0}
              labelStyle={{display: 'none'}}
              contentStyle={{textTransform: 'uppercase'}}
              cursor={false}
            />
            {getBar()}
          </BarChart>
        </ResponsiveContainer>
      </Col>
      <Col className="bar-chart-item-2" sm="12" md="5">
        <Indicators data={barLabels} columnsNumber={columnsNumber} colors={colors}  displayValue={false}/>
      </Col>
    </StyledRow>
  );
};

export default BacBarChart;
