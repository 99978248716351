import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Dash from "./dash";
import BacPieChart from "./dash/sub-dash/BacPieChart";
import BacBarChart from "./dash/sub-dash/BacBarChart";
import BacTable from "./dash/sub-dash/BacTable";
import { sumData } from "utils";
import { bar52, pie51 } from "utils/colors";
import styled from "styled-components";
import DataContext from "context/DataContext";
import { CONSEQPIEBAR, CONSEQPIEBARLABELS, CONSEQUENCESTABLABELS } from "utils/constants";
import { device } from "utils/breakpoints";
const StyledContainer = styled(Container)`
  height: 100%;
  @media only screen and ${device.xs}{
    .row-1-1, .row-1-2, .row-2-1 {
      border-bottom-width: 0;
    }
  }
  @media only screen and ${device.sm}{
    .row-1-1 {
      border-right-width: 0;
      border-bottom-width: 0;
    }
    .row-1-2 {
      border-bottom-width: 0;
    }
    .row-2-1 {
      border-right-width: 0;
    }
  }
  @media only screen and ${device.md}{
    .row-1-1 {
      border-right-width: 0;
      border-bottom-width: 0;
    }
    .row-2-1 {
      border-right-width: 0;
    }
    .row-1-2 {
      border-bottom-width: 0;
    }
    .page-row-1 {
      height: 465px;
    }
    .page-row-2 {
      height: 489px;
    }
  }
`;
const Consequences = () => {
  const {
    dataContext: { consequences: {anomaliesByConsequenceForYear2022, anomaliesByConsequenceForYear, anomaliesByConsequenceAndCenterForYear2022} },
  } = useContext(DataContext);
  const dataPie = Object.keys(anomaliesByConsequenceForYear2022[0]).map((key) => {
    return {
      name: CONSEQPIEBAR[key],
      value: anomaliesByConsequenceForYear2022[0][key]
    }
  });
  const barLabels = Object.keys(anomaliesByConsequenceForYear2022[0]).map((item) => {
    return {
      name: CONSEQPIEBARLABELS[item]
    }
  });
  return (
    <StyledContainer>
      <Row className="page-row-1">
        <Col xs="12" sm="6" md="6" className="row-1-1">
          <Dash title="Répartition ANO par type de conséquence (2022)">
            <BacPieChart
              data={dataPie}
              colors={pie51}
              displayLabel={false}
              displayValue={false}
            />
          </Dash>
        </Col>
        <Col xs="12" sm="6" md="6" className="row-1-2">
          <Dash title="Répartition ANO par conséquence et par année">
            <BacBarChart
              data={anomaliesByConsequenceForYear}
              colors={bar52}
              barLabels={barLabels}
              isFull={true}
            />
          </Dash>
        </Col>
      </Row>
      <Row className="page-row-2">
        <Col xs="12" sm="12" md="6" className="row-2-1">
          <Dash title="Répartition ANO par centre et par conséquence (2022)">
            <BacTable
              data={[...anomaliesByConsequenceAndCenterForYear2022, sumData(anomaliesByConsequenceAndCenterForYear2022)]}
              dataHead={Object.keys(anomaliesByConsequenceAndCenterForYear2022[0]).map(item => CONSEQUENCESTABLABELS[item])}
            />
          </Dash>
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default Consequences;
