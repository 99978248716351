import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Dash from "./dash";
import BacTable from "./dash/sub-dash/BacTable";
import BacPieChart from "./dash/sub-dash/BacPieChart";
import BacBarChart from "./dash/sub-dash/BacBarChart";
import { sumData } from "utils";
import { bar42, pie43 } from "utils/colors";
import styled from "styled-components";
import { useDeviceWidth } from "utils/useDeviceDetect";
import DataContext from "context/DataContext";
import { FMPIE3LABELS, FMPIE4LABELS, FMPIE5LABELS, FMTABLABELS } from "utils/constants";
import { device, sizes } from "utils/breakpoints";
const StyledContainer = styled(Container)`
  height: 100%;
  @media only screen and ${device.xs}{
    .row-1-1, .row-1-2, .row-2-1, .row-2-2 {
      border-bottom-width: 0;
    }
  }
  @media only screen and ${device.sm}{
    .row-1-1 {
      border-right-width: 0;
      border-bottom-width: 0;
    }
    .row-1-2 {
      border-bottom-width: 0;
    }
    .row-2-1 {
      border-right-width: 0;
    }
    .row-2-1, .row-2-2 {
      border-bottom-width: 0;
    }
  }
  @media only screen and ${device.md}{
    .row-1-1 {
      border-right-width: 0;
      border-bottom-width: 0;
    }
    .row-1-2 {
      border-bottom-width: 0;
    }
    .row-2-2, .row-2-1 {
      border-right-width: 0;
    }
    .page-row-1 {
      height: 493px;
    }
    .page-row-2 {
      height: 593px;
    }

  }
`;
const getColumnsNumber = (wdth, isLast) => {
  if(wdth <= sizes.sm) {
    return 2;
  }
  if(wdth >= sizes.sm && wdth <= sizes.md) {
    if(isLast) {
      return 3;
    }
    return 2;
  }
  if(wdth >= sizes.md) {
    return 1;
  }
}
const Material = () => {
  const wdth = useDeviceWidth();
  const {
    dataContext: { material: {FMAnomaliesByDomainForYear2022, FMAnomaliesByOuvTypeForYear, FMAnomaliesByTensionTypeForYear2022, FMAnomaliesByOuvTypeForYear2022, FMAnomaliesByCauseForYear2022} },
  } = useContext(DataContext);
  const newData = Object.keys(FMAnomaliesByCauseForYear2022[0]).map((key) => {
    return {
      name: FMPIE5LABELS[key],
      value: FMAnomaliesByCauseForYear2022[0][key]
    }
  });
  const newData1 = Object.keys(FMAnomaliesByTensionTypeForYear2022[0]).map((key) => {
    return {
      name: FMPIE3LABELS[key],
      value: FMAnomaliesByTensionTypeForYear2022[0][key]
    }
  });
  const newData2 = Object.keys(FMAnomaliesByOuvTypeForYear2022[0]).map((key) => {
    return {
      name: FMPIE4LABELS[key],
      value: FMAnomaliesByOuvTypeForYear2022[0][key]
    }
  });
  const barLabels = Object.keys(FMAnomaliesByOuvTypeForYear2022[0]).map((item) => {
    return {
      name: FMPIE4LABELS[item]
    }
  });
  return (
    <StyledContainer>
      <Row className="page-row-1">
        <Col xs="12" sm="6" md="6" className="row-1-1">
          <Dash title="Répartition ANO FM par centre et par type de tension (2022)">
            <BacTable
              data={[...FMAnomaliesByDomainForYear2022, sumData(FMAnomaliesByDomainForYear2022)]}
              dataHead={Object.keys(FMAnomaliesByDomainForYear2022[0]).map(item => FMTABLABELS[item])}
            />
          </Dash>
        </Col>
        <Col xs="12" sm="6" md="6" className="row-1-2">
          <Dash title="Répartition ANO FM par type d'ouvrage et par année">
            <BacBarChart
              data={FMAnomaliesByOuvTypeForYear}
              colors={bar42}
              barLabels={barLabels}
            />
          </Dash>
        </Col>
      </Row>
      <Row className="page-row-2">
        <Col xs="12" sm="6" md="4" className="row-2-1">
          <Dash title="Répartition ANO FM par type de tension (2022)">
            <BacPieChart
              data={newData1}
              miniSize={true}
              colors={pie43}
              displayLabel={false}
              displayValue={false}
            />
          </Dash>
        </Col>
        <Col xs="12" sm="6" md="4" className="row-2-2">
          <Dash title="Répartition Anomalies FM par ouvrage (2022)">
            <BacPieChart
              data={newData2}
              colors={pie43}
              miniSize={true}
              displayLabel={false}
              displayValue={false}
            />
          </Dash>
        </Col>
        <Col xs="12" sm="12" md="4" className="row-2-3">
          <Dash title="Répartition ANO FM par type cause (2022)">
            <BacPieChart
              data={newData}
              colors={pie43}
              miniSize={true}
              displayLabel={false}
              displayValue={false}
            />
          </Dash>
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default Material;
