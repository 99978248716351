import ChartLabel from 'components/shared/ChartLabel';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { device } from 'utils/breakpoints';
const StyledRow = styled(Row)`
  
  @media only screen and ${device.xs}{
    &.pie-indicator-row {
      .style-item {
        & > .col:nth-child(1) {
          flex-basis: ${(props) => {
            return props.value ? 150 : 250
          }}px;
          white-space: break-spaces;
        }
      }
      & > .col {
        &:nth-child(1) {
          flex-basis: 50px;
        }
        &:nth-child(2) {
          flex-basis: calc(100% - 50px);
        }
        
      }
    }
  }
  @media only screen and ${device.sm}{
    &.pie-indicator-row {
      & > .col {
        &:nth-child(1) {
          flex-basis: 50px;
        }
        
      }
      .pie-indicator-2 {
        flex-basis: ${(props) => {
          console.log(props.columns)
          return props.value ? 150 : (props.columns === 1 ? 257 : 95)
        }}px;
        .col-name {
          flex-basis: ${(props) => {
            return props.columns === 1 ? 222 : 146
          }}px;
        }
      }
    }
  }
  @media only screen and ${device.md}{
    &.pie-indicator-row {
      .style-item {
        & > .col:nth-child(1) {
          flex-basis: ${(props) => {
            return props.value ? 150 : 250
          }}px;
          white-space: break-spaces;
        }
      }
      & > .col {
        &:nth-child(1) {
          flex-basis: 50px;
        }
        &:nth-child(2) {
          flex-basis: calc(100% - 50px);
        }
        
      }
    }
  }
`;
const PieIndicator = ({item, color, displayValue, columnsNumber}) => {
  const {name, value} = item;

  return (
      <StyledRow className={`pie-indicator-row`} value={displayValue} columns={columnsNumber}>
        <Col className="pie-indicator-1">
          <ChartLabel color={color} />
        </Col>
        <Col className="pie-indicator-2 pie-label">
          <Row className="style-item">
            <Col className="col-name">{name}</Col>
            {displayValue && <Col>{value.toLocaleString()}</Col>}
          </Row>
        
        </Col>
      </StyledRow>
  );
};

export default PieIndicator;
