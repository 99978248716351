// human
//1
export const FHANOBYCENTER = {
    InterBreakerLine: 'Ligne inter disjoncteur',
    btCell: 'Tranche BT',
    center: 'Centre',
    cutOffBlock: 'Bloc de coupure',
    transitLine: 'Ligne de transit',
    station: 'Poste',
    fhTotal: 'Total FH'
}
export const FHANOBYCENTERLABELS = {
    InterBreakerLine: 'Ligne inter-disjoncteur',
    btCell: 'Tranche BT',
    center: 'Centre',
    cutOffBlock: 'Bloc de coupure',
    transitLine: 'Ligne de transit',
    station: 'Poste',
    fhTotal: 'Total ANO FH'
}
//4
export const FHPIE2 = {
    ci: 'Configuration inexacte',
    dp: 'Défaut procédures',
    dc: 'Défauts de conception',
    mi: 'Manipulation intempestive',
    mic: 'Manoeuvre inopportune conduite',
    toad: 'Terre oubliée après déconsignation',
}
export const FHPIE2LABELS = {
    ci: 'Configuration inexacte',
    dp: 'Défaut procédures',
    dc: 'Défauts de conception',
    mi: 'Manipulation intempestive',
    mic: 'Manoeuvre inopportune conduite',
    toad: 'Terre oubliée après déconsignation',
}

// repartition
//1
export const REPARTITIONTAB = {
    center: 'Centre',
    fh: 'FH',
    fm: 'FM',
    ae: 'AE'
}
export const REPARTITIONTABLABELS = {
    center: 'Centre',
    fh: 'FH',
    fm: 'FM',
    ae: 'AE'
}
//2
export const REPARTITIONPIEBAR = {
    fh: 'FH',
    fm: 'AE',
    ae: 'FM'
}
export const REPARTITIONPIELABELS = {
    fh: 'Anomalies facteur humain (FH)',
    fm: 'Anomalies facteur matériel (FM)',
    ae: "Anomalies à l'étude (AE)",
}
export const REPARTITIONBARLABELS = {
    fh: 'FH',
    fm: 'FM',
    ae: 'AE',
}
// synthesis
export const SYNTHESISTAB = {
    center: 'centre',
    nb_ano: 'nb_ano',
    nb_eqf: 'nb_eqf',
    ratio: 'ratio'
}
export const SYNTHESISTABLABELS = {
    center: 'Centre',
    nb_ano: 'Nombre EQF',
    nb_eqf: 'Nombre ANO',
    ratio: 'Ratio ANO/EQF'
}
// material
export const FMTAB = {
    center: 'Centre',
    bt: 'BT',
    ht: 'HT',
    ns: 'NS',
}
export const FMTABLABELS = {
    center: 'Centre',
    bt: 'Basse tension (BT)',
    ht: 'Haute tension (HT)',
    ns: 'Non spécifié (NS)',
}
export const FMPIE3 = {
    bt: "BT",
    ht: "HT",
    ns: "NS",
}
export const FMPIE3LABELS = {
    bt: "BT",
    ht: "HT",
    ns: "NS",
}
export const FMPIE4 = {
    bc: "Bloc de coupure",
    lt: "Ligne de transit",
    lid: "Ligne inter disjoncteur",
    b: "Poste",
    tb: "Tranche BT",
}
export const FMPIE4LABELS = {
    bc: "Bloc de coupure",
    lt: "Ligne de transit",
    lid: "Ligne inter disjoncteur",
    b: "Poste",
    tb: "Tranche BT",
}
export const FMPIE5 = {
    cna: "Correctif non appliqué",
    d: "Diverses",
    f: "Fonctionnelle",
    iai: "Inconnue : abs informations",
    iasc: "Inconnue : analysée sans conclusion",
    ina: "Inconnue : non analysée",
    t: "Technologique",
}
export const FMPIE5LABELS = {
    cna: "Correctif non appliqué",
    d: "Diverses",
    f: "Fonctionnelle",
    iai: "Inconnue : abs informations",
    iasc: "Inconnue : analysée sans conclusion",
    ina: "Inconnue : non analysée",
    t: "Technologique",
}
// consquences
export const CONSEQPIEBAR = {
    fi: 'Fermeture intempestive',
    ft: 'Fermeture tardive',
    nf: 'Non fermeture',
    no: 'Non ouverture',
    np: 'Not Provided',
    oi: 'Ouverture intempestive',
    ot: 'Ouverture tardive',
    sc: 'Sans conséquence',
}
export const CONSEQPIEBARLABELS = {
    fi: 'Fermeture intempestive',
    ft: 'Fermeture tardive',
    nf: 'Non fermeture',
    no: 'Non ouverture',
    np: 'Not Provided',
    oi: 'Ouverture intempestive',
    ot: 'Ouverture tardive',
    sc: 'Sans conséquence',
}

export const CONSEQUENCESTAB = {
    center: 'center',
    fi: 'Fermeture intempestive',
    ft: 'Fermeture tardive',
    nf: 'Non fermeture',
    no: 'Non ouverture',
    oi: 'Ouverture intempestive',
    ot: 'Ouverture tardive',
    sq: 'Sans conséquence',
    np: 'Not Provided',
}
export const CONSEQUENCESTABLABELS = {
    center: 'Centre',
    fi: 'FI',
    ft: 'FT',
    nf: 'NF',
    no: 'NO',
    oi: 'OI',
    ot: 'OT',
    sq: 'SC',
    np: 'NR',
}