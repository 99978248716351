import React from 'react';
import DataContext from './DataContext';


function GlobalContextProvider({
  children,
}){
  const [dataContext, setDataContext] = React.useState({
    synthesis: {
      aggregatedAnoEqfPerYear2022: [],
      anoPerYear: [],
      eqfPerYear: []
    },
    repartition: {
      anomaliesByTypeForYear2022: []
    },
    human: {
      FHAnomaliesByCentreAndOuvrageType2022: [],
      FHAnomaliesByCauseForYear: [],
      // 3.4
      FHAnomaliesByCauseForYear2022: []
    },
    material: {
      // 4.1
      FMAnomaliesByDomainForYear2022: [],
      FMAnomaliesByTensionTypeForYear2022: [],
      FMAnomaliesByOuvTypeForYear2022: [],
      FMAnomaliesByCauseForYear2022: []
    },
    consequences: {
      // 5.1
      anomaliesByConsequenceForYear2022: [],
      // 5.2
      anomaliesByConsequenceForYear: [],
      // 5.3
      anomaliesByConsequenceAndCenterForYear2022: []
    },
  });

  return (
    <DataContext.Provider value={{ dataContext, setDataContext }}>
          {children}
    </DataContext.Provider>
  );
}

export default GlobalContextProvider;
  