import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import NavBar from './nav-bar';
import Content from './content';
import { getData } from 'services/data';
import DataContext from 'context/DataContext';
import Loading from './Loading';
import Error from './Error';
import { device } from 'utils/breakpoints';
const StyledContainer = styled(Container)`
  @media only screen and ${device.xs}{
  }
  @media only screen and ${device.sm}{
  }
  @media only screen and ${device.md}{
  }
  height: 100%;
  width: 1920px;
  .appLayout-row {
    
    height: 100%;
    .appLayout-1 {
      background-color: var(--bac-blue);
      flex-basis: 70px;
      background-size: cover;
      min-height: 100vh;
    }
    .appLayout-2 {
      flex-basis: calc(100% - 70px);
      // overflow-x: hidden;
    }
  }
`;
const AppLayout = () => {
  const { dataContext, setDataContext } =
    useContext(DataContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    setLoading(true);
    void getData().then((response) => {
      setDataContext({
        ...dataContext,
        ...response,
      });
      setLoading(false);
    }).catch((error) => {
      setError(error.message);
      setLoading(false);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <StyledContainer>
      <Row className="appLayout-row">
        <Col className="appLayout-1">
          <NavBar />
        </Col>
        <Col className="appLayout-2">
          {loading ? <Loading /> : (error ? <Error message={error} /> : <Content />)}
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default AppLayout;
