import React from 'react';

const DataContext = React.createContext({
  dataContext: {
    synthesis: {
      aggregatedAnoEqfPerYear2022: [],
      anoPerYear: [],
      eqfPerYear: [],
    },
    repartition: {
      anomaliesByTypeForYear2022: []
    },
    human: {
      FHAnomaliesByCentreAndOuvrageType2022: [],
      FHAnomaliesByCauseForYear: [],
      FHAnomaliesByCauseForYear2022: []
    },
    material: {
      FMAnomaliesByDomainForYear2022: [],
      FMAnomaliesByTensionTypeForYear2022: [],
      FMAnomaliesByOuvTypeForYear2022: [],
      FMAnomaliesByCauseForYear2022: []
    },
    consequences: {
      anomaliesByConsequenceForYear2022: [],
      anomaliesByConsequenceForYear: [],
      anomaliesByConsequenceAndCenterForYear2022: []
    },
    //repartition
    
  },
  setDataContext: (data) => {
    // do nothing.
  },
});
DataContext.displayName = 'Data Context';
export default DataContext;
