import { Spinner } from "react-bootstrap";
import styled from "styled-components";
import { device } from "utils/breakpoints";


const StyledDiv = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .spinner-bloc {
    margin-top: 50px;
    & > div {
      height: 200px;
      width: 200px;
    }
  }
  h4 {
    text-align: center;
  }
`;
export default function Loading() {

  return (
    <StyledDiv>
      <h1>En attente...</h1>
      <h4>Merci de patienter pendant le chargement des informations de connexion</h4>
      <div className="spinner-bloc">
      <Spinner animation="border" size="lg" />
      </div>
    </StyledDiv>
  );
}