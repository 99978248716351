import _ from "lodash";
import { sizes } from "./breakpoints";
export const sumData = (arr) => {
   const clonedData = _.cloneDeep(arr);   
    const result = clonedData.reduce((acc, val) => {
       Object.keys(val).forEach(key => {
          if(key !== 'center'){
            if(val[key] === undefined) {
               val[key] = 0;
            }
            acc[key] += val[key];
          };
       });
       if(acc['center'] !== 'Total'){
         acc['center'] = 'Total';
      };
       return acc;
    });
    return result;
    
};

export const getYAxisTickLineMargin = (wdth) => {
   if(wdth <= 576) {
     return 5;
   }
   if(wdth > 576 && wdth <= 992) {
      return 4;
   }
   if(wdth > 992 && wdth <= 1200) {
      return 4;
   }
   return 4;
}

export const getXAxisTickBarMargin = (wdth) => {
   if(wdth <= sizes.sm) {
     return 12;
   }
   if(wdth > 576 && wdth <= 992) {
      return 15;
    }
   if(wdth > 992 && wdth <= 1200) {
      return 15;
   }
   return 15;
}
export const getYAxisTickBarMargin = (wdth) => {
   if(wdth <= 576) {
     return 2;
   }
   if(wdth > 576 && wdth <= 992) {
      return 4;
   }
   if(wdth > 992 && wdth <= 1200) {
      return 4;
   }
   return 4;
}

export const getXAxisTickLineMargin = (wdth) => {
   if(wdth <= sizes.sm) {
     return 12;
   }
   if(wdth > 576 && wdth <= 992) {
      return 15;
    }
   if(wdth > 992 && wdth <= 1200) {
      return 15;
   }
   return 15;
}
export const getYAxisBarWidth = (wdth) => {
   if(wdth <= sizes.sm) {
     return 32;
   }
   if(wdth > 576 && wdth <= 992) {
      return 39;
   }
   if(wdth > 992 && wdth <= 1200) {
      return 39;
   }
   return 39;
 }
export const getYAxisLineWidth = (wdth) => {
   if(wdth <= 576) {
     return 39;
   }
   if(wdth > 576 && wdth <= 992) {
      return 39;
   }
   if(wdth > 992 && wdth <= 1200) {
      return 39;
   }
   return 39;
 }

 export const getPieHeight = (wdth) => {
   if(wdth <= sizes.md) {
     return 170;
   } else {
     return 234;
   }
 }