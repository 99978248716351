import React, { useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import BacTable from './dash/sub-dash/BacTable';
import BacPieChart from './dash/sub-dash/BacPieChart';
import BacLineChart from './dash/sub-dash/BacLineChart';
import Dash from './dash';
import { line13, pie11 } from 'utils/colors';
import styled from "styled-components";
import DataContext from 'context/DataContext';
import { sumData } from 'utils';
import { SYNTHESISTABLABELS } from 'utils/constants';
import { device } from 'utils/breakpoints';
const StyledContainer = styled(Container)`
  height: 100%;
  @media only screen and ${device.xs}{
    .row-1-1, .row-1-2, .row-2-1, .row-2-2 {
      border-bottom-width: 0;
    }
  }
  @media only screen and ${device.sm}{
    .row-1-1 {
      border-right-width: 0;
      border-bottom-width: 0;
    }
    .row-1-2 {
      border-bottom-width: 0;
    }
    .row-2-1 {
      border-right-width: 0;
    }
    .row-2-1, .row-2-2 {
      border-bottom-width: 0;
    }
  }
  @media only screen and ${device.md}{
    .row-1-1 {
      border-right-width: 0;
      border-bottom-width: 0;
    }
    .row-1-2 {
      border-bottom-width: 0;
    }
    .row-2-2, .row-2-1 {
      border-right-width: 0;
    }
    .page-row-1 {
      height: 434px;
    }
    .page-row-2 {
      height: 520px;
    }
  }
`;
const dataPie = [
  {name: "Anomalies", value: 920},
  {name: "EQF hors anomalies", value: 6735},
];
const ratioAno = [
  {"year": "2018","Lille": 0.0656,"Lyon": 0.1486,"Marseille": 0.1623,"Nancy": 0.1116,"Nantes": 0.0677,"Paris": 0.3227,"Toulouse": 0.1094,"Moyenne": 0.1293},
  {"year": "2019","Lille": 0.1263,"Lyon": 0.1563,"Marseille": 0.2324,"Nancy": 0.1222,"Nantes": 0.0939,"Paris": 0.3058,"Toulouse": 0.1373,"Moyenne": 0.1534},
  {"year": "2020","Lille": 0.1200,"Lyon": 0.1743,"Marseille": 0.2296,"Nancy": 0.1195,"Nantes": 0.0681,"Paris": 0.3200,"Toulouse": 0.1678,"Moyenne": 0.1592},
  {"year": "2021","Lille": 0.0736,"Lyon": 0.1548,"Marseille": 0.1975,"Nancy": 0.1230,"Nantes": 0.0821,"Paris": 0.3073,"Toulouse": 0.1212,"Moyenne": 0.1420},
  {"year": "2022","Lille": 0.0569,"Lyon": 0.1530,"Marseille": 0.1357,"Nancy": 0.1232,"Nantes": 0.0721,"Paris": 0.1797,"Toulouse": 0.1283,"Moyenne": 0.1202},
  {"year": "2023*","Lille": 0.0768,"Lyon": 0.1290,"Marseille": 0.1110,"Nancy": 0.1017,"Nantes": 0.0823,"Paris": 0.1836,"Toulouse": 0.1258,"Moyenne": 0.1134}
];
const Synthesis = () => {
  const {
    dataContext: { synthesis },
  } = useContext(DataContext);
  const { aggregatedAnoEqfPerYear2022, eqfPerYear, anoPerYear } = synthesis;
  const head = Object.keys(aggregatedAnoEqfPerYear2022[0]);
  return (
    <StyledContainer>
      <Row className='page-row-1'>
        <Col xs="12" sm="6" md="6" className="row-1-1">
          <Dash title="Part d’anomalies parmi les EQF (2022)">
            <BacPieChart data={dataPie} colors={pie11} />
          </Dash>
        </Col>
        <Col xs="12" sm="6" md="6" className="row-1-2">
          <Dash title="Nombre d’EQF et d’ANO par centre (2022)">
            <BacTable
              data={[...aggregatedAnoEqfPerYear2022, sumData(aggregatedAnoEqfPerYear2022)]}
              dataHead={head.map(item => SYNTHESISTABLABELS[item])} />
          </Dash>
        </Col>
      </Row>
      <Row className='page-row-2'>
        <Col xs="12" sm="6" md="4" className="row-2-1">
        <Dash title="Nombre d’EQF par centre et par année">
            <BacLineChart
              data={eqfPerYear}
              colors={line13}
              ticks={[0, 200, 400, 600, 800, 1000, 1200, 1400, 1600, 1800, 2000]}
              columnsNumber={2}
            />
          </Dash>
        </Col>
        <Col xs="12" sm="6" md="4" className="row-2-2">
        <Dash title="Nombre d’ANO par centre et par année">
            <BacLineChart data={anoPerYear} colors={line13} ticks={[0, 50, 100, 150, 200, 250, 300]} columnsNumber={2}/>
          </Dash>
        </Col>
        <Col xs="12" sm="12" md="4">
        <Dash title="Ratio ANO/EQF par centre et par année">
            <BacLineChart data={ratioAno} colors={line13} ticks={[0, 0.1, 0.15, 0.2, 0.25, 0.30, 0.35]} columnsNumber={2}/>
          </Dash>
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default Synthesis;
