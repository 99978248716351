import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Dash from "./dash";
import BacTable from "./dash/sub-dash/BacTable";
import BacBarChart from "./dash/sub-dash/BacBarChart";
import BacPieChart from "./dash/sub-dash/BacPieChart";
import { sumData } from "utils";
import { bar32, pie33, pie34, pie35} from "utils/colors";
import styled from "styled-components";
import { useDeviceWidth } from "utils/useDeviceDetect";
import DataContext from "context/DataContext";
import { FHANOBYCENTERLABELS, FHPIE2LABELS } from "utils/constants";
import { device } from "utils/breakpoints";
const StyledContainer = styled(Container)`
  height: 100%;
  @media only screen and ${device.xs}{
    .row-1-1, .row-1-2, .row-2-1, .row-2-2 {
      border-bottom-width: 0;
    }
  }
  @media only screen and ${device.sm}{
    .row-1-1 {
      border-right-width: 0;
      border-bottom-width: 0;
    }
    .row-1-2 {
      border-bottom-width: 0;
    }
    .row-2-1 {
      border-right-width: 0;
    }
    .row-2-1, .row-2-2 {
      border-bottom-width: 0;
    }
  }
  @media only screen and ${device.md}{
    .row-1-1 {
      border-right-width: 0;
      border-bottom-width: 0;
    }
    .row-1-2 {
      border-bottom-width: 0;
    }
    .row-2-2, .row-2-1 {
      border-right-width: 0;
    }
    .page-row-1 {
      height: 493px;
    }
    .page-row-2 {
      height: 566px;
    }
  }
`;
const dataHumainFMHT = [
  {name: "Aucune donnée", value: 100000}
];
const dataHumainFMNS = [
  {name: "Configuration inexacte", value: 7000},
  {name: "Terre oubliée après déconsignation", value: 4007},
  {name: "Manoeuvre inopportune conduite", value: 4107},
  {name: "Manipulation intempestive", value: 4707},
  {name: "Défaut de conception", value: 2207},
  {name: "Défaut procédure", value: 4007},
];
const Human = () => {
  const wdth = useDeviceWidth();
  const {
    dataContext: { human },
  } = useContext(DataContext);
  const { FHAnomaliesByCentreAndOuvrageType2022, FHAnomaliesByCauseForYear2022, FHAnomaliesByCauseForYear } = human;
  const barLabels = Object.keys(FHAnomaliesByCauseForYear2022[0]).map((item) => {
    return {
      name: FHPIE2LABELS[item]
    }
  });
  const newData = Object.keys(FHAnomaliesByCauseForYear2022[0]).filter((key) => {
    return key !== 'year'
  }).map((key) => {
    return {
      name: FHPIE2LABELS[key],
      value: FHAnomaliesByCauseForYear2022[0][key]
    }
  });
  return (
    <StyledContainer>
      <Row className="page-row-1" >
        <Col xs="12" sm="6" md="6" className="row-1-1">
          <Dash title="Répartition ANO FH par centre et par type d'ouvrage (2022)">
            <BacTable
              data={[...FHAnomaliesByCentreAndOuvrageType2022, sumData(FHAnomaliesByCentreAndOuvrageType2022)]}
              dataHead={Object.keys(FHAnomaliesByCentreAndOuvrageType2022[0]).map(item => FHANOBYCENTERLABELS[item])}
            />
          </Dash>
        </Col>
        <Col xs="12" sm="6" md="6" className="row-1-2">
          <Dash title="Répartition ANO FH par cause et par année">
            <BacBarChart
              data={FHAnomaliesByCauseForYear}
              colors={bar32}
              barLabels={barLabels}
              isFull={true}
            />
          </Dash>
        </Col>
      </Row>
      <Row className="page-row-2" >
        <Col xs="12" sm="6" md="4" className="row-2-1">
          <Dash title="Répartition ANO FH HT par type (2022)">
            <BacPieChart
              data={dataHumainFMHT}
              colors={pie33}
              miniSize={true}
              displayLabel={false}
              displayValue={false}
              isFull={wdth <= 1200}
            />
          </Dash>
        </Col>
        <Col xs="12" sm="6" md="4" className="row-2-2">
          <Dash title="Répartition ANO FH par cause (2022)">
            <BacPieChart
              data={newData}
              colors={pie34}
              miniSize={true}
              displayLabel={false}
              displayValue={false}
              isFull={wdth <= 1200}
            />
          </Dash>
        </Col>
        <Col xs="12" sm="12" md="4" className="row-2-3">
          <Dash title="Répartition ANO FH par type d'ouvrage (2022)">
            <BacPieChart
              data={dataHumainFMNS}
              colors={pie35}
              miniSize={true}
              displayLabel={false}
              displayValue={false}
              isFull={wdth <= 576 || wdth > 992}
            />
          </Dash>
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default Human;
