import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import Info from "images/info.svg"
import { ModalDash } from './sub-dash/ModalDash';
import { device } from 'utils/breakpoints';
const StyledRow = styled(Row)`
    @media only screen and ${device.xs}{
        padding: 2px;
        &.dash-row {
            row-gap: 30px;
            .dash-title-1 {
                font-size: 18px;
                line-height: 23px;
            }
        }
    }
    @media only screen and ${device.sm}{
        &.dash-row {
            row-gap: 30px;
            .dash-title-1 {
                font-size: 20px;
                line-height: 28px;
            }
        }
    }
    @media only screen and ${device.md}{
        &.dash-row {
            row-gap: 30px;
            .dash-title-1 {
                font-size: 24px;
                line-height: 32px;
            }
        }
    }
    &.dash-row {
        margin: 10px;
        @media (min-width: 577px) and (max-width: 992px) {
            padding: 10px;
        }
        height: calc(100% - 20px);
        .dash-title-1 {
            color: var(--bac-grayText);
            font-weight: 700;
            font-family: Open Sans;
            .dash-title-row {
                .col-title {
                    @media (max-width: 576px) {
                        flex: 1;
                    }
                    @media (min-width: 576px) {
                        flex-basis: calc(100% - 30px);
                    }
                }
                .col-img {
                    @media (max-width: 576px) {
                        margin-left: 10px;
                    }
                }
            }
        }
        .dash-title-2 {
            flex-basis: calc(100% - 90px);
        }
    }
`;
const Dash = ({title, children}) => {
    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <StyledRow className="dash-row flex-column">
        <Col md={12} className="dash-title-1">
            <Row className='justify-content-between dash-title-row'>
                <Col className='col-title'>{title}</Col>
                <Col className="col-img"><img style={{cursor: "pointer"}} onClick={handleShow} src={Info} alt="info" /></Col>
            </Row>
            
        </Col>
        <Col className="dash-title-2" md={12}>
            {children}
        </Col>
        <ModalDash show={show} handleClose={handleClose} title={title}/>
    </StyledRow>
  );
};

export default Dash;
