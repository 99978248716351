import { useRouteError } from "react-router-dom";


export default function Error({message}) {
  const error = useRouteError();
  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p style={{color: 'red'}}>
        <i>{error?.data || message}</i>
      </p>
    </div>
  );
}